exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alternative-payment-methods-js": () => import("./../../../src/pages/alternative-payment-methods.js" /* webpackChunkName: "component---src-pages-alternative-payment-methods-js" */),
  "component---src-pages-analytics-js": () => import("./../../../src/pages/analytics.js" /* webpackChunkName: "component---src-pages-analytics-js" */),
  "component---src-pages-apple-pay-js": () => import("./../../../src/pages/apple-pay.js" /* webpackChunkName: "component---src-pages-apple-pay-js" */),
  "component---src-pages-cards-js": () => import("./../../../src/pages/cards.js" /* webpackChunkName: "component---src-pages-cards-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fraud-solution-js": () => import("./../../../src/pages/fraud-solution.js" /* webpackChunkName: "component---src-pages-fraud-solution-js" */),
  "component---src-pages-google-pay-js": () => import("./../../../src/pages/google-pay.js" /* webpackChunkName: "component---src-pages-google-pay-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-one-click-payments-js": () => import("./../../../src/pages/one-click-payments.js" /* webpackChunkName: "component---src-pages-one-click-payments-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-samsung-pay-js": () => import("./../../../src/pages/samsung-pay.js" /* webpackChunkName: "component---src-pages-samsung-pay-js" */),
  "component---src-pages-subscription-js": () => import("./../../../src/pages/subscription.js" /* webpackChunkName: "component---src-pages-subscription-js" */),
  "component---src-pages-vacancies-enterprise-deal-desk-analyst-js": () => import("./../../../src/pages/vacancies/enterprise-deal-desk-analyst.js" /* webpackChunkName: "component---src-pages-vacancies-enterprise-deal-desk-analyst-js" */),
  "component---src-pages-vacancies-index-js": () => import("./../../../src/pages/vacancies/index.js" /* webpackChunkName: "component---src-pages-vacancies-index-js" */),
  "component---src-pages-vacancies-network-engineer-js": () => import("./../../../src/pages/vacancies/network-engineer.js" /* webpackChunkName: "component---src-pages-vacancies-network-engineer-js" */),
  "component---src-pages-vacancies-node-js-developer-js": () => import("./../../../src/pages/vacancies/node-js-developer.js" /* webpackChunkName: "component---src-pages-vacancies-node-js-developer-js" */),
  "component---src-pages-vacancies-product-design-js": () => import("./../../../src/pages/vacancies/product-design.js" /* webpackChunkName: "component---src-pages-vacancies-product-design-js" */),
  "component---src-pages-vacancies-senior-php-developer-js": () => import("./../../../src/pages/vacancies/senior-php-developer.js" /* webpackChunkName: "component---src-pages-vacancies-senior-php-developer-js" */)
}

